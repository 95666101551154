<template>
  <div id="app">
    <div class="">
      <div>
        <div class="custom-navbar-wrapper mb-3">
          <b-container class="container-navbar">
            <Navbar />
          </b-container>
        </div>

        <div class="body-wrapper">
          <router-view />
        </div>

        <div class="footer">
          <Footer />
        </div>
      </div>
    </div>

    <img src="@/assets/dots-pattern.svg" id="background-img" v-show="false" />

    <component :is="'style'">
      .body-wrapper::after { content: ""; background: url('{{ bgImgUrl }}')
      center center fixed; background-size: cover; min-width: 100vw; min-height:
      100vh; opacity: 0.3; top: 0; left: 0; bottom: 0px; right: 0; position:
      fixed; z-index: -1; }
    </component>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      bgImgUrl: "",
    };
  },
  mounted() {
    var bgImg = document.querySelectorAll("#background-img")[0];
    if (bgImg) {
      this.bgImgUrl = bgImg.src;
    }
  },
};
</script>

<style scoped>
#app {
  font-family: Roboto, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #010e78;
}

.custom-navbar-wrapper {
  font-size: 1.4rem;
  color: white !important;
  background-color: #010e78;
  /* background: linear-gradient(
    180deg,
    rgb(235, 235, 235, 1) 0%,
    rgb(235, 235, 235, 0.6) 97%,
    rgba(255, 255, 255, 0.1) 100%
  ); */
}

.container:not(.container-navbar, .container-footer) {
  /* background-color: rgba(117, 117, 117, 0.1);
  border-radius: 15px; */
  padding-bottom: 20px;
  padding-left: 25px;
  padding-right: 25px;
}

.body-wrapper {  
  overflow: auto;
  min-height: 100vh;
}

.footer {
  background: #000949;
  padding-top: 5px;
  position: relative;
  bottom: 0;
}
</style>
