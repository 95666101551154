import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/o-firmie',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/partnerzy',
    name: 'Partners',
    component: () => import('../views/Partners.vue')
  },
  {
    path: '/kontakt',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/ubezpieczenia/medyczne',
    name: 'MedicalInsurance',
    component: () => import('../views/insurance/Medical.vue')
  },
  {
    path: '/ubezpieczenia/na-zycie',
    name: 'LifeInsurance',
    component: () => import('../views/insurance/Life.vue')
  },
  {
    path: '/ubezpieczenia/komunikacyjne',
    name: 'TrafficInsurance',
    component: () => import('../views/insurance/Traffic.vue')
  },
  {
    path: '/ubezpieczenia/dla-firm',
    name: 'CompanyInsurance',
    component: () => import('../views/insurance/Company.vue')
  },
  {
    path: '/ubezpieczenia/majatkowe',
    name: 'FortuneInsurance',
    component: () => import('../views/insurance/Fortune.vue')
  },
  {
    path: '/ubezpieczenia/turystyczne',
    name: 'TouristInsurance',
    component: () => import('../views/insurance/Tourist.vue')
  },
  {
    path: '/ubezpieczenia/biura-rachunkowe',
    name: 'AccountingInsurance',
    component: () => import('../views/insurance/Accounting.vue')
  },
  {
    path: '/ubezpieczenia/rolne',
    name: 'AgriculturalInsurance',
    component: () => import('../views/insurance/Agricultural.vue')
  },
  {
    path: '/ubezpieczenia/rower',
    name: 'BikeInsurance',
    component: () => import('../views/insurance/Bike.vue')
  },
  { path: '/404', component: () => import('../views/NotFound.vue') },
  { path: '*', redirect: '/404' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
