<template>
  <b-navbar toggleable="lg" type="dark" class="navbar-wrapper">
    <b-navbar-brand to="/">
      <img
        class="fluid"
        style="max-width: 100%"
        alt="Ubezpieczenia MS Konsulting"
        src="@/assets/logo-msk-white.svg"
    /></b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="m-auto">
        <div v-for="(menuItem, idx) in menu" :key="idx">
          <b-nav-item-dropdown dropright 
            :text="menuItem.text"
            right
            v-if="menuItem.children && menuItem.children.length"
            :active="
              menuItem.children.filter(x => x.url === $route.path).length > 0
            "
          >
            <b-dropdown-item
              v-for="(child, idx) in menuItem.children"
              :key="idx"
              :to="child.url"
              :active="child.url === $route.path"
              >{{ child.text }}</b-dropdown-item
            >
          </b-nav-item-dropdown>

          <b-nav-item
            :to="menuItem.url"
            v-else
            :active="menuItem.url === $route.path"
          >
            <b-icon v-if="menuItem.icon" :icon="menuItem.icon"></b-icon>
            <span v-if="menuItem.text">{{ menuItem.text }}</span>
          </b-nav-item>
        </div>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import menu from "../config/menu.json";

export default {
  computed: {
    menu() {
      return menu;
    },
  },
};
</script>

<style scoped>
.nav-item {
  padding-left: 5px;
  padding-right: 5px;
}

/* ::v-deep .nav-link {
  color: #010e78 !important;
} */
</style>
