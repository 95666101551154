<template>
  <a
    href="javascript:location='mailto:\u0062\u0069\u0075\u0072\u006f\u0040\u0075\u0062\u0065\u007a\u0070\u0069\u0065\u0063\u007a\u0065\u006e\u0069\u0061\u006d\u0073\u006b\u002e\u0063\u006f\u006d';void 0"
  >
    <b-icon-envelope-fill class="mr-2"/><span v-html="getEmail()"> </span
  ></a>
</template>

<script>
export default {
  name: "EmailDisplay",
  methods: {
    getEmail() {
      return "\u0062\u0069\u0075\u0072\u006f\u0040\u0075\u0062\u0065\u007a\u0070\u0069\u0065\u0063\u007a\u0065\u006e\u0069\u0061\u006d\u0073\u006b\u002e\u0063\u006f\u006d";
    },
  },
};
</script>
