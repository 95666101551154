<template>
  <b-container class="container-footer pt-4 pb-4">
    <b-row style="text-align: center;">
      <b-col sm="12" md="4">
        <p>Kontakt:</p>
        <p class="">
          <span itemprop="telephone"
            ><b-icon-telephone-fill class="mr-2" />784 571 335</span
          >
        </p>
        <p>
          <span itemprop="telephone"
            ><b-icon-telephone-fill class="mr-2" />666 200 505</span
          >
        </p>

        <p>
          <EmailDisplay />
        </p>
      </b-col>
      <b-col sm="12" md="4">
        <p class="">
          <b-icon-house-fill class="mr-2" />
          Lublin, ul. Wileńska 21 (pawilony), LSM
        </p>

        <p class="">
          Dostępne parkingi:<br />
          - przy pawilonach<br />
          - pod CH Leclerc (Zana)<br />
          - wzdłuż ul. Zana i ul. Krasińskiego
        </p>
      </b-col>

      <b-col sm="12" md="4">
        <p>
          <b-link to="/ubezpieczenia/medyczne">Ubezpieczenia medyczne</b-link>
        </p>
        <p>
          <b-link to="/ubezpieczenia/turystyczne"
            >Ubezpieczenia turystyczne</b-link
          >
        </p>
        <p>
          <b-link to="/ubezpieczenia/komunikacyjne"
            >Ubezpieczenia komunikacyjne</b-link
          >
        </p>
        <p>
          <b-link to="/ubezpieczenia/na-zycie">Ubezpieczenia na życie</b-link>
        </p>

        <p><b-link to="/"> Strona główna</b-link></p>
        <p><b-link to="/kontakt">Kontakt</b-link></p>
        <p><b-link to="/o-firmie">O firmie</b-link></p>
        <p><b-link to="/partnerzy">Partnerzy</b-link></p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import EmailDisplay from "@/components/EmailDisplay.vue";
export default {
  components: { EmailDisplay },
  computed: {},
};
</script>

<style scoped>
.container-footer {
  color: rgba(209, 209, 209, 0.842);
  font-size: 0.8em;
}

a {
  color: rgba(209, 209, 209, 0.842);
  text-decoration: none;
}

a:hover {
  color: white;
}

p {
  margin: 5px;
}
</style>
